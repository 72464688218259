@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

// Per l'override del colore $primary della palette in formato HSB (colore #FF3333 https://rgb.to/ff3333):

$primary-h: 166;
$primary-s: 100;
$primary-b: 56;

// Per l'override della famiglia di caratteri
// $font-family-serif: 'Custom Font', Georgia, serif;
// $font-family-sans-serif: 'Custom Font', Arial, Helvetica, sans-serif;
// $font-family-monospace: 'Custom Font', 'Courier New', Courier, monospace;

// Importazione libreria SCSS di bootstrap-italia
@import "bootstrap-italia/src/scss/bootstrap-italia";

// @font-face {
//     font-family: 'Source Sans Pro';
//     font-style: italic;
//     font-weight: 300;
//     src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidh18Smxg.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }

:root {
  // --main-blue-color: #3366FF;
  // --main-red-color: #FF6666;
  --h3-color: #666666;
  // --main-white-color-border: 3px #fff solid;
  // --main-blue-color-border: 3px #13296b solid;
  // --main-red-color-border: 3px #CC0000 solid;
  // --main-color-text: #ffffff;
  // --main-color-border: #c0c0c0;
  // --a-link-footer-color: #f17663;
  // --background-color-footer: #2f3539;
  // --main-error-color: red;
  // --main-success-color: green;
  // --main-font-size: 1em;
  // --main-font-weight: 500;
  // --main-font-size-table-response: 0.8em;
}

.red {
  color: var(--main-error-color);
}

.green {
  color: var(--main-success-color);
}

body {
  margin: 0;
  // font-family: Montserrat,Helvetica,Arial,sans-serif;
  line-height: 1.2;
  // font: var(--main-font-weight);
  // font-size: var(--main-font-size);
  // -webkit-font-smoothing: antialiased;
}

.page-content {
  padding-top: 10px;
  width: 90%;
  min-height: 58vh;
  margin: auto;
}

h3 {
  color: var(--h3-color)
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;

}

// .modale-background{
//     z-index: 2 !important;
//     position: fixed;
//     top: 0;
//     left: 0;
//     background-color: rgba(0, 0, 0, 0.7);
//     width: 100%;
//     height: 100vh;
// }

// .modale-content{
//   width: 50%; 
//   margin: auto;
// }

// .modale-stampaNre-content{
//     width: 80%; 
//     margin: auto;
//     max-height: 80vh !important;
//     overflow-y: scroll;
//     overflow-x: hide;
// }
// .modale-storico-content{
//   width: 40%; 
//   margin: auto;
// }
// .modal-dialog{
//   max-width: 100% !important; 
// }
.maxSizeContent {
  width: 60%;
  margin: auto;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.searchButtonContainer {
  text-align: right;
}

.rounded {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: .5em 0;
  margin: .25rem;
  line-height: 1em;
  color: var(--main-color-text);
  background-color: #6c757d !important;
  border-radius: 1em !important;
}

.breadCrumb {
  text-align: center;
  min-height: 50px;
  float: left;
}

.separatore {
  min-width: 20%;
  min-height: 25px;
  float: left;
  border-bottom: 1px #c0c0cc solid;

}

.sezione-breadcrumb {
  margin-top: 50px;
}

.number_button {
  line-height: 1em;
  font-weight: 700;
}

// .active_button {
//   background-color: #007bff !important ;
// }

// .text_button {
//   display: inline-flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   padding: 10px;
//   font-size: 1rem;
//   font-weight: bold;
//   line-height: 1.5;
//   color: #6c757d;
//   text-align: center;
//   text-decoration: none;
//   white-space: nowrap;
//   vertical-align: middle;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   background-color: transparent;
//   border: none;
//   border-radius: .25rem;
//   transition: background-color .15s ease-out,color .15s ease-out;
// }




@media (max-width: 576px) {
  .container-breadcrumb {
    display: none;
  }
}

@media (max-width: 576px) {

  // .modale-content{
  //   width: 90%;
  // }
  .maxSizeContent {
    width: 90%;
    margin: auto;
  }

  //   .modal-dialog{
  //     max-width: 500px !important; 
  //   }
  //   .modale-stampaNre-content{
  //     width: 95%; 
  //     margin: auto;
  // }
  // .modale-storico-content{
  //   width: 95%; 
  //   margin: auto;
  // }
  .searchButtonContainer {
    text-align: center;
  }
}

.main-content {
  margin-top: 5%;
  min-height: 80vh;
  text-align: center;
}

.title_1 {
  font-weight: 500;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
}

.text_1 {
  font-weight: 500;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  text-align: justify;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 1rem !important;
}

.home-content {
  width: 60%;
  margin: auto;
  padding-top: 100px;
  min-height: 65vh;
}

// .alert-box{
//   margin-top: 50px;
// }

.access-button {
  // margin-top: 50px;
  text-align: center;
  min-height: 250px;
}

@media (max-width: 576px) {
  .home-content {
    width: 95%;
    margin: auto;
    padding-top: 50px;
  }

  .text_1 {
    text-align: justify;
  }

  .title_1 {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .header-card-seleziona-asset {
    display: none;
  }
}

.nav-text {
  color: var(--bs-body-color);
  text-decoration: none;
}

.header-card {
  margin-left: auto !important;
}

.header-card:after {
  margin: 0;
}

// .custom-card-no-top-border {
//   border-top: 0px !important;
//   border-top-left-radius: 0% !important;
//   border-top-right-radius: 0% !important;
// }

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -10px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}


.form-group {
  margin-bottom: 1em !important;
}

.accordion {
  border-bottom: 0 !important;
}

.link-pointer {
  cursor: pointer;
}

.riga-valore {
  margin-bottom: 0em !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:disabled {
  color: var(--bs-white);
}

// Override default design-kit translations
label[for="fileUpload"]>span, a.page-link>span {
  display: none !important;
}
label[for="fileUpload"]:after {
  content: 'Scegli il file';
}

.form-group label {
  z-index: 4 !important;
}

.attribute-row-deleted .form-group input,
.attribute-row-deleted .form-group select,
.attribute-list-item-not-valid {
  color: var(--bs-danger);
}

th .form-check label {
  margin-bottom: -10px !important;
}

td .form-check {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

it-table.search-modal-table div.form-check {
  min-height: 0px;
}

.row-selected {
  background-color: rgba(0, 0, 0, 0.075);
  font-weight: bold;
  //val(--bs-table-hover-bg);
}

.bs-is-sticky {
  z-index: 999 !important;
}